import React from 'react';
import './more.css';

const More = () => {
  return (
    <div className="gis__more blurrable">
        <a className="gis__more-container" href="https://wilis.pg.edu.pl/studenci/studia-podyplomowe/systemy-informacji-geograficznej-geoinformacja" target="_blank" rel="noreferrer">
            <h1>Dowiedz <br /> się więcej</h1>
        </a>
    </div>
  )
}

export default More