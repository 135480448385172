import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './sm.css';

const Sm = () => {
  return (
    <div className="gis__sm blurrable">
        <div className="gis__sm-container">
            <a className="gis__sm-container_fb" href="https://www.facebook.com/KatedraGeodezji/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} /><p>KatedraGeodezji</p></a>
            <a className="gis__sm-container_ig" href="https://www.instagram.com/katedra_geodezji_pg/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /><p>katedra_geodezji_pg</p></a>
            <a className="gis__sm-container_yt" href="https://www.youtube.com/@katedrageodezji_pg" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /><p>@katedrageodezji_pg</p></a>
        </div>
  </div>
  )
}

export default Sm