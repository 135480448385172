import React from 'react';
import './register.css';

const Register = () => {
  return (
    <div className="gis__register blurrable">
        <a className="gis__register-container" href="https://rekrutacja.pg.edu.pl/" target="_blank" rel="noreferrer">
            <h1>Rekrutacja</h1>
        </a>
    </div>
  )
}

export default Register