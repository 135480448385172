import React from 'react';
import { About, Location, Lecturer, Subjects, Gallery } from '../../components';

const Main = () => {
  return (
    <div>
      <About />
      <Location />
      <Lecturer />
      <Subjects />
      <Gallery />
    </div>
  )
}

export default Main