import React from 'react';
import './backtop.css';

const Backtop = () => {
  return (
    <div className="gis__backtop blurrable">
        <a href="#home"><button className="gis__backtop-arrow"><p>&#10096;</p></button></a>
    </div>
  )
}

export default Backtop