import React, { useRef } from 'react';
import './collapsible.css';

const Collapsible = ({id, show, onShow, label, children}) => {
    const handleShow = () => {
        if(show === false) {
          onShow && onShow(id);
        }
        else {
          onShow(!show);
        }
    }
    const contentRef = useRef();

    return (
        <div className={`gis__collapsible ${show ? "on" : ""}`}>
            <button className="gis__collapsible-btn" onClick={handleShow}>
                {label}
                <div className="collapsible-indicator">
                    <p className="arrow-down" style={show ? { display: "none" } : { display: "flex" }}>+</p>
                    <p className="arrow-top" style={show ? { display: "flex" } : { display: "none" }}>-</p>
                </div>
                </button>
                <div className={`content-parent ${show ? "on" : ""}`}
                    ref={contentRef} style={show ? {height: contentRef.current.scrollHeight + "px" } : { height: "0px" }}>
                    <div className="gis__collapsed">{children}</div>
                </div>
        </div>
    )
}

export default Collapsible