import React from 'react'
import './header.css';

const Header = () => {
  return (
    <div id="home" className="gis__header blurrable">
      <div className="gis__header-content">
          <h1>Systemy Informacji Geograficznej,<br/>Geoinformacja</h1>
          <h2>Politechnika Gdańska</h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="750" version="1.1" viewBox="0 0 1920 750">
            <path fill="#0a2647" d="M0 0H1920V750H0z"></path>
            <path fill="#205295" d="M0 474l213-11 214 3 213 62 213-56h214l213 62 213-92 214 60 213-19v268H0z"></path>
            <path fill="#1b4985" d="M0 541l213-30 214-35 213 7 213 63 214 13 213-62 213 54 214-47 213-18v265H0z"></path>
            <path fill="#154075" d="M0 589l213-20 214 36 213 1 213 3 214-21 213 19 213-53 214-14 213 55v156H0z"></path>
            <path fill="#113765" d="M0 642l213-6 214-23 213-18 213 36 214-27 213-9 213-19 214 1 213 15v159H0z"></path>
            <path fill="#0d2e56" d="M0 632l213 46 214-57 213 52 213-47 214 41 213 10 213-44 214 26 213-14v106H0z"></path>
            <path fill="#0a2647" d="M0 719l213-48 214 9 213-1 213 16 214 1 213-1 213 11 214-29 213 27v47H0z"></path>
          </svg>
      </div>
    </div>
  )
}

export default Header