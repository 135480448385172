import React, { useState } from 'react';
import Collapsible from '../collapsible/Collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import './subjects.css';

const Subjects = () => {
    const [showId, setShowId] = useState();
    const handleShow = id => setShowId(id);
  return (
    <div className="gis__subjects blurrable">
        <a className="anchor-subjects" id="gis_subjects"></a>
        <div className="gis__subjects-heading">
            <div className="gis__subjects-heading-pin"><p><FontAwesomeIcon icon={faBook}/></p></div>
            <h1 className="gis__subjects-heading-text gradient__text">Przedmioty</h1>
        </div>
        <div className="gis__subjects-container">
            <Collapsible id={1} show={showId === 1} onShow={handleShow} label={"Wprowadzenie do GIS"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Podstawowe pojęcia i definicje systemów informacji geograficznej. Podział systemów informacji geograficznej. Przepisy prawne dotyczące infrastruktury informacji przestrzennej. Zastosowania danych oraz wyników analiz przestrzennych.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>11 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={2} show={showId === 2} onShow={handleShow} label={"Kartografia cyfrowa"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Współczesne metody kartograficzne, kartogramy i kartodiagramy, metody podziału klasowego, symbolizacja danych przestrzennych. Stosowane w Polsce i na świecie układy odniesienia, układy współrzędnych, odwzorowania kartograficzne.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>5 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={3} show={showId === 3} onShow={handleShow} label={"Metody pozyskiwania danych"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Pozyskiwanie danych przestrzennych w terenie oraz zdalnie, nawigacja satelitarna, pozyskanie danych za pomocą odbiorników GNSS wysokiej dokładności, pozyskiwanie danych za pomocą urządzeń mobilnych. Przegląd dostępnych technik pomiarowych. Dane zawarte w państwowym zasobie geodezji i kartografii. Europejska i Polska infrastruktura informacji przestrzennej.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>3 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>10 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={4} show={showId === 4} onShow={handleShow} label={"Modele danych przestrzennych"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Pojęcia i definicje modelu danych, relacyjne bazy danych, modele danych przestrzennych. Budowa wektorowego i rastrowego modelu danych, model danych topologicznych. Wytyczne techniczne oraz standardy w systemach informacji geograficznej. Najpopularniejsze formaty zapisu danych w systemach informacji geograficznej.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>5 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={5} show={showId === 5} onShow={handleShow} label={"Analiza danych wektorowych"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Budowanie oraz edytowanie danych wektorowych, łączenie danych przestrzennych z nieprzestrzennymi, uzupełnianie danych w tabeli atrybutowej, digitalizacja danych przestrzennych. Relacje topologiczne w bazach danych wektorowych. Narzędzia analizy wektorowej w przetwarzaniu informacji geograficznej.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>3 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>16 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={6} show={showId === 6} onShow={handleShow} label={"Analiza danych rastrowych"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Wykorzystanie i zastosowanie metod interpolacji danych. Tworzenie numerycznych modeli terenu, praca na danych rastrowych. Narzędzia algebry map do przetwarzania informacji geograficznej. Wykorzystanie danych numerycznego modelu terenu do analiz hydrologicznych.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>11 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={7} show={showId === 7} onShow={handleShow} label={"Analiza danych przestrzennych"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Połączenie narzędzi do obsługi danych wektorowych i rastrowych, opracowanie wyników analizy danych przestrzennych z wielu źródeł. Konwersja danych wektorowych do postaci rastrowej i rastrowej do wektorowej.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>11 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={8} show={showId === 8} onShow={handleShow} label={"Teledetekcja i fotogrametria"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Zakres promieniowania elektromagnetycznego wykorzystywanego w teledetekcji. Metody klasyfikacji obrazu. Metody filtracji i korekcji obrazów satelitarnych.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>11 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={9} show={showId === 9} onShow={handleShow} label={"Teledetekcja aktywna - LiDAR"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Definicja oraz podział technologii LiDAR. Parametry oraz zastosowanie chmury punktów z Państwowego Zasobu Geodezji i Kartografii. Przetwarzanie danych z naziemnego skaningu laserowego.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>11 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={10} show={showId === 10} onShow={handleShow} label={"Pozyskiwanie danych z drona"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Planowanie nalotu i kalibracja urządzeń fotogrametrii niskiego pułapu. Planowanie osnowy polowej. Post-processing i agregacja danych pomiarowych. Eksport danych do systemów GIS.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>11 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={11} show={showId === 11} onShow={handleShow} label={"Analiza danych 3D"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Tworzenie danych 3D w oprogramowaniu GIS. Przeprowadzenie analizy widoczności i zacienienia w lokalnej scenie 3D. Wizualizacja oraz animacja 3D.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>5 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={12} show={showId === 12} onShow={handleShow} label={"Rozwiązania online w GIS"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Przegląd dostępnych rozwiązań serwerowych na potrzeby budowania portali mapowych. Obsługa narzędzia ArcGIS online. Serwery danych przestrzennych.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>4 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={13} show={showId === 13} onShow={handleShow} label={"Geostatystyka"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Metody statystyki przestrzennej, podstawowe zagadnienia z statystyki danych. Przedstawianie wyników danych za pomocą wykresów, map prawdopodobieństwa. Metoda high-low clustering do prezentowania danych geograficznych.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>1 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>5 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={14} show={showId === 14} onShow={handleShow} label={"Wspomaganie decyzji"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Wprowadzenie do analizy wielokryterialnej. Wykorzystanie analizy wielokryterialnej jak i metod zbiorów rozmytych do wspomagania decyzji.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>1 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>6 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={15} show={showId === 15} onShow={handleShow} label={"Zaawansowane analizy przestrzenne"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Sposoby automatyzacji procesu przetwarzania danych przestrzennych z zastosowaniem narzędzi Model Builder oraz zintegrowanych środowisk programistycznych dostępnych w oprogramowaniu GIS. Budowanie własnych narzędzi na podstawie skryptów w Python. Udostępnianie danych i narzędzi programistycznych w otwartych repozytoriach.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>19 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={16} show={showId === 16} onShow={handleShow} label={"Analiza sieci"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Przygotowanie danych do budowania zestawu danych sieciowych. Transformacja parametrów ruchu do kosztów poruszania się w sieci. Wykonywanie analiz przestrzennych na podstawie opracowanej sieci.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>1 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>5 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={17} show={showId === 17} onShow={handleShow} label={"Inżynieria danych, podstawy uczenia maszynowego"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Obsługa dużych zbiorów danych (Big Data) na potrzeby przeprowadzania analiz przestrzennych, wizualizacja danych pochodzących z różnych źródeł. Wykorzystywanie metod uczenia maszynowego w systemach informacji geograficznej.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>10 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={18} show={showId === 18} onShow={handleShow} label={"Podstawy BIM"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Podstawowe pojęcia i definicje Modelowania Informacji o Budynku (BIM). Modelowanie wnętrza budynków w oprogramowaniu GIS. Wizualizacja danych BIM.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>5 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <Collapsible id={19} show={showId === 19} onShow={handleShow} label={"Zarządzanie projektami GIS"}>
                <div className="about-subject">
                    <h2>O przedmiocie</h2>
                    <p>Identyfikacja projektu. Studium wykonalności projektu. Tworzenie oprogramowania GIS. Cechy oprogramowania GIS. Etapy tworzenia oprogramowania.</p>
                </div>
                <div className="hours-subject">
                    <div className="hours-subject-top"><h2>Liczba godzin:</h2></div>
                    <div className="hours-subject-bottom">
                        <div className="hours-top">
                            <h3>Wykładów</h3>
                            <p>2 godz.</p>
                        </div>
                        <div className="hours-bottom">
                            <h3>Ćwiczeń</h3>
                            <p>4 godz.</p>
                        </div>
                    </div>
                </div>
            </Collapsible>
            <br />
        </div>
    </div>
  )
}

export default Subjects