import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardUser } from '@fortawesome/free-solid-svg-icons';
import { Lecturerslider } from "../";
import AI2023 from '../../assets/AI2023.jpg';
import AW2023 from '../../assets/AW2023.jpg';
import KBo2023 from '../../assets/KBo2023.jpeg';
import KS2023 from '../../assets/KS2023.jpeg';
import PG2023 from '../../assets/PG2023.jpeg';
import ASZ2023 from '../../assets/ASZ2023.jpg';
import MS2023 from '../../assets/MS2023.jpg';
import PT2023 from '../../assets/PT2023.jpg';
import PW2023 from '../../assets/PW2023.jpg';
import TW2023 from '../../assets/TW2023-2.jpg';
import KB2023 from '../../assets/KB2023.png';
import KBr2023 from '../../assets/KBr2023.png';
import KM2023 from '../../assets/KM2023.png';
import MF2023 from '../../assets/MF2023.png';
import MKC2023 from '../../assets/MKC2023.png';
import JU2023 from '../../assets/JU2023.jpg';
import MostWiedzy from '../../assets/most.png';
import Linkedin from '../../assets/linkedin.png';
import LogoUG from '../../assets/logoug.png';
import Scopus from '../../assets/scopus.png';
import './lecturer.css';

const Lecturer = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 700;
    var showNumber = 3;
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    if(width > 1450) {
        showNumber = 3;
    }
    if(width > 700 && width <= 1450) {
        showNumber = 2;
    }
    if(width >= 0 && width <= 700) {
        showNumber = 1;
    }
  return (
    <div className="gis__lecturer blurrable">
        <a className="anchor-lecturer" id="gis_lecturer"></a>
        <div className="gis__lecturer-heading">
            <div className="gis__lecturer-heading-pin"><p><FontAwesomeIcon icon={faChalkboardUser}></FontAwesomeIcon></p></div>
            <h1 className="gis__lecturer-heading-text gradient__text">Prowadzący</h1>
        </div>
        <div className="gis__lecturer-panel" style={{ maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
            <Lecturerslider
                show={showNumber}
                infiniteLoop
            >
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr inż. Adam Inglot</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={AI2023} alt="Adam Inglot" />
                            </div>
                            <div className="description__name">
                                <h3>Kierownik studiów<br/>Adiunkt</h3>
                                <h4>Katedra Geodezji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Absolwent Akademii Górniczo-Hutniczej w Krakowie. W swoich badaniach zajmuje się kartografią cyfrową, generalizacją kartograficzną, stosowaniem algorytmów w GIS. Ponad dziesięcioletnie doświadczenie w prowadzeniu zajęć o tematyce GIS.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                    <a href="https://mostwiedzy.pl/pl/adam-inglot,1017456-1?" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr inż. Katarzyna Bobkowska</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={KB2023} alt="Katarzyna Bobkowska" />
                            </div>
                            <div className="description__name">
                                <h3>Adiunktka</h3>
                                <h4>Katedra Geodezji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Absolwentka Politechniki Gdańskiej oraz Uniwersytetu Morskiego w Gdyni. Wykorzystuje współczesne metody fotogrametrii i teledetekcji na potrzeby rozwiązywania interdyscyplinarnych problemów naukowych. Swoje doświadczenie dzieli z innymi specjalistami w dziedzinach takich jak: medycyna, inżynieria środowiska, architektura, psychologia czy informatyka.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://mostwiedzy.pl/pl/katarzyna-bobkowska,229652-1?" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Mgr inż. Krzysztof Borkowski</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={KBo2023} alt="Krzysztof Borkowski" />
                            </div>
                            <div className="description__name">
                                <h3>Ekspert GIS</h3>
                                <h4>ConnectPoint</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Absolwent Uniwersytetu Warszawskiego. Specjalista GIS, wieloletnie doświadczenie w obszarze GIS, szczególnie w branży szeroko pojętej infrastruktury, transportu i AEC.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://www.linkedin.com/in/kborkowski/?locale=pl_PL" target="_blank" rel="noreferrer"><h6><img src={Linkedin} alt="Linkedin" />Linkedin</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr Katarzyna Bradtke</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={KBr2023} alt="Katarzyna Bradtke" />
                            </div>
                            <div className="description__name">
                                <h3>Adiunktka</h3>
                                <h4>Pracownia Teledetekcji i Analizy Przestrzennej</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Zakład Oceanografii Fizycznej, Instytucie Oceanografii, Uniwersytetu Gdańskiego. Zajmuje się satelitarnymi badaniami morza, w tym głównie jakości wód, zmian temperatury powierzchni morza i pokrywy lodowej.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://old.ug.edu.pl/pracownik/449/katarzyna_bradtke" target="_blank" rel="noreferrer"><h6><img src={LogoUG} alt="Logo_UG" />Uniwersytet Gdański</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Prof. dr hab. inż. Mariusz Figurski</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={MF2023} alt="Mariusz Figurski" />
                            </div>
                            <div className="description__name">
                                <h3>Profesor</h3>
                                <h4>Katedra Geodezji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Dyrektor Centrum Modelowania Meteorologicznego IMGW-PIB, prof. Politechniki Gdańskiej. Były prorektor Wojskowej Akademii Technicznej. Działalność badawcza, modelowanie meteorologiczne, big data, geodezja i nawigacja satelitarna, inżynieria kosmiczna.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://mostwiedzy.pl/pl/mariusz-figurski,1137185-1?" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Mgr inż. Paulina Gajownik</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={PG2023} alt="Paulina Gajownik" />
                            </div>
                            <div className="description__name">
                                <h3>Dyrektorka Sektora Edukacji</h3>
                                <h4>ESRI</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Dyrektor Sektora Edukacji Esri Polska. Współpracuje z wieloma uczelniami w Polsce. Absolwentka Wojskowej Akademii Technicznej na Wydziale Inżynierii Lądowej i Geodezji. Specjalizuje się w zagadnieniach związanych z rynkiem IT oraz geoinformatyką w zastosowaniach biznesowych.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://www.linkedin.com/in/paulina-gajownik-mucka-2891a8148/" target="_blank" rel="noreferrer"><h6><img src={Linkedin} alt="Linkedin" />Linkedin</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr Marta Kuc-Czarnecka</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={MKC2023} alt="Marta Kuc-Czarnecka" />
                            </div>
                            <div className="description__name">
                                <h3>Adiunktka</h3>
                                <h4>Katedra Statystyki i Ekonometrii</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Adiunkta na WZiE PG. Posiada doktorat z ekonomii ze specjalnością statystyka i ekonometria. Specjalizuje się w miernikach syntetycznych oraz w statystyce i ekonometrii przestrzennej. Była ekspertka Eurofound ds. konwergencji społecznej.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://mostwiedzy.pl/pl/marta-kuc-czarnecka,1016048-1" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr inż. Krystyna Michałowska</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={KM2023} alt="Krystyna Michałowska" />
                            </div>
                            <div className="description__name">
                                <h3>Adiunktka</h3>
                                <h4>Katedra Geodezji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Absolwentka Akademii Górniczo-Hutniczej w Krakowie. Ekspert w zakresie systemów informacji geograficznej (GIS), fotogrametrii i teledetekcji. Posiada bogate doświadczenie w modelowaniu informacji geograficznej 3D/4D z wykorzystaniem technologii teledetekcyjnych i wieloczasowych analiz przestrzenno-czasowych GIS w badaniach stanu i zmian środowiska. Jest członkinią IEEE (Institute of Electrical and Electronics Engineers) oraz ISPRS (International Society for Photogrammetry and Remote Sensing). Jest również ekspertem Narodowego Centrum Badań i Rozwoju (NCBiR).</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://mostwiedzy.pl/pl/krystyna-michalowska,1226526-1" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Mgr inż. Marta Samulowska</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={MS2023} alt="Marta Samulowska" />
                            </div>
                            <div className="description__name">
                                <h3>Doktorantka</h3>
                                <h4>Zakład Geoinformatyki, Kartografii i Teledetekcji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Doktorantka na Wydziale Geografii i Studiów Regionalnych na Uniwersytecie Warszawskim. Jej obszarem zainteresowania są zastosowania GeoWeb w Citizen Science oraz WebGIS. Do 2018 roku związana z ESRI Polska.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://www.linkedin.com/in/marta-samulowska-536531b0/" target="_blank" rel="noreferrer"><h6><img src={Linkedin} alt="Linkedin" />Linkedin</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr inż. Anna Sobieraj-Żłobińska</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={ASZ2023} alt="Anna Sobieraj-Żłobińska" />
                            </div>
                            <div className="description__name">
                                <h3>Adiunktka</h3>
                                <h4>Katedra Geodezji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Absolwentka Uniwersytetu Warmińsko-Mazurskiego w Olsztynie. Pracowała w Okręgowym Przedsiębiorstwie Geodezyjno-Kartograficznym oraz w firmie Karto-Graf w Olsztynie. Od 2010 na Politechnice Gdańskiej.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://mostwiedzy.pl/pl/anna-sobieraj-zlobinska,741129-1?" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Mgr inż. Karol Stachura</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={KS2023} alt="Karol Stachura" />
                            </div>
                            <div className="description__name">
                                <h3>Specjalista GIS</h3>
                                <h4>geoinformatyka.com.pl</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Geoinformatyk, szkoleniowiec, programista, urzędnik samorządowy. Posiada dwudziestoletnie doświadczenie w przetwarzaniu, integracji i kontroli danych przestrzennych. Autor bloga geoinformatyka.com.pl</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://www.linkedin.com/in/karol-stachura-48386543/?originalSubdomain=pl" target="_blank" rel="noreferrer"><h6><img src={Linkedin} alt="Linkedin" />Linkedin</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr inż. Paweł Tysiąc</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={PT2023} alt="Paweł Tysiąc" />
                            </div>
                            <div className="description__name">
                                <h3>Adiunkt</h3>
                                <h4>Katedra Geodezji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Specjalista z zakresu fotogrametrii i Teledetekcji. Działalność badawcza i komercyjna związana z analizami geoprzestrzennymi, modelowaniem 3D oraz pozyskiwaniem danych przestrzennych z poziomów: stacjonarnego, mobilnego, lotniczego i satelitarnego.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://mostwiedzy.pl/pl/pawel-tysiac,799067-1?" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Prof. dr hab. inż. Piotr Wężyk</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={PW2023} alt="Piotr Wężyk" />
                            </div>
                            <div className="description__name">
                                <h3>Profesor</h3>
                                <h4>Katedra Zarządzania Zasobami Leśnymi</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Ekspert z zakresu zastosowań Geoinformatyki w leśnictwie, ochronie środowiska i w rolnictwie. Zatrudniony na Wydziale Leśnym Uniwersytetu Rolniczego w Krakowie. Zainteresowania badawcze: LiDAR (ALS, TLS, HLS, MLS, ULS, SLS), określanie stanu zdrowotnego lasów z wykorzystaniem fotogrametrii (w tym BSP) i teledetekcji satelitarnej, GEOBIA, Urban Forestry, analizy GIS 3D i 4D.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://www.linkedin.com/in/piotr-wezyk-a3b9b775/?originalSubdomain=pl" target="_blank" rel="noreferrer"><h6><img src={Linkedin} alt="Linkedin" />Linkedin</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr inż. Tadeusz Widerski</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={TW2023} alt="Tadeusz Widerski" />
                            </div>
                            <div className="description__name">
                                <h3>Adiunkt</h3>
                                <h4>Katedra Geodezji</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Absolwent Wydziału Geodezji i Kartografii Uniwersytetu Warmińsko Mazurskiego w Olsztynie, adiunkt w Katedrze Geodezji Wydziału Inżynierii Lądowej i Środowiska Politechniki Gdańskiej. Doświadczenie zawodowe w zakresie geodezyjnych pomiarów inżynierskich, fotogrametrii bliskiego zasięgu i modelowania 3D.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://mostwiedzy.pl/pl/tadeusz-widerski,14001-1?" target="_blank" rel="noreferrer"><h6><img src={MostWiedzy} alt="Most Wiedzy" />Most Wiedzy</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Mgr inż. Agnieszka Wochna</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={AW2023} alt="Agnieszka Wochna" />
                            </div>
                            <div className="description__name">
                                <h3>Specjalistka</h3>
                                <h4>IMGW</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Obecnie pracuje w IMGW-PIB, przez 10 lat zatrudniona w Centrum GIS Uniwersytetu Gdańskiego. Certyfikowana tutorka akademicka, absolwentka programu "Mistrzowie Dydaktyki”. Aktywnie wykorzystuje GIS i Pythona w działalności badawczej skupiającej się na monitoringu i ochronie środowiska Morza Bałtyckiego.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://www.linkedin.com/in/agnieszka-wochna-5ba90b95/" target="_blank" rel="noreferrer"><h6><img src={Linkedin} alt="Linkedin" />Linkedin</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide">
                <div className="gis__lecturer-container">
                    <div className="gis__lecturer-container_name">
                        <h2>Dr hab. Jacek Urbański</h2>
                    </div>
                    <div className="gis__lecturer-container_description">
                        <div className="description__lecturer">
                            <div className="description__photo">
                                <img src={JU2023} alt="Agnieszka Wochna" />
                            </div>
                            <div className="description__name">
                                <h3>Specjalista</h3>
                                <h4>Instytut Oceanologii PAN</h4>
                            </div>
                        </div>
                        <div className="description__text">
                            <p>Oceanograf fizyczny. Zajmuje się badaniem Arktyki. Przez ponad 30 lat prowadził na Uniwersytecie Gdańskim różne zajęcia związane z GIS. Autor książki "GIS w badaniach przyrodniczych". Obecnie pracuje w Instytucie Oceanologii PAN w zespole IT jako analityk danych.</p>
                            <div className="description__panel">
                                <div id="desc4" className="description__text-panel">
                                <a href="https://www.scopus.com/authid/detail.uri?authorId=6603693901" target="_blank" rel="noreferrer"><h6><img src={Scopus} alt="Scopus" />Scopus</h6></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Lecturerslider>
        </div>    
    </div>
  )
}

export default Lecturer