import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import LogoGis from '../../assets/logo-gis.svg';
import Enauczanie from '../../assets/enauczanie.png';
import './navbar.css';

const Menu = () => (
  <>
  <p id="btn1"><a href="#home">Home</a><div id="line1" /></p>
  <p id="btn2"><a href="#gis_about">O kierunku</a><div id="line2" /></p>
  <p id="btn3"><a href="#gis_location">Lokalizacja</a><div id="line3" /></p>
  <p id="btn4"><a href="#gis_lecturer">Prowadzący</a><div id="line4" /></p>
  <p id="btn5"><a href="#gis_subjects">Przedmioty</a><div id="line5" /></p>
  <p id="btn6"><a href="#gis_contact">Kontakt</a><div id="line6" /></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gis__navbar blurrable">
      <div className="gis__navbar-links">
        <div id="logo" className="gis__navbar-links_logo">
        <a href="#home"><img id="gis-logo" src={LogoGis}/></a>
        </div>
        <div className="gis__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="gis__navbar-enauczanie">
        <a href="https://enauczanie.pg.edu.pl/moodle/" target="_blank" rel="noreferrer"><button type="button"><img src={Enauczanie} alt="eNauczanie"/></button></a>
      </div>
      <div className="gis__navbar-menu">
        {toggleMenu 
          ? <RiCloseLine size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className="gis__navbar-menu_container scale-up-ver-top">
            <div className="gis__navbar-menu_container-links">
              <Menu />
              <div className="gis__navbar-menu_container-links-enauczanie">
                <a href="https://enauczanie.pg.edu.pl/moodle/" target="_blank" rel="noreferrer"><button type="button"><img src={Enauczanie} alt="eNauczanie"/></button></a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar